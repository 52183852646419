import { AppLayoutProps } from './interfaces';

const defaultsClassic: Record<AppLayoutProps.ContentType, AppLayoutState> = {
  default: {
    navigationOpen: true,
    minContentWidth: 280,
    maxContentWidth: undefined,
  },
  cards: {
    navigationOpen: true,
    minContentWidth: 280,
    maxContentWidth: undefined,
  },
  form: {
    navigationOpen: false,
    minContentWidth: 280,
    maxContentWidth: 800,
  },
  table: {
    navigationOpen: true,
    minContentWidth: 280,
    maxContentWidth: undefined,
  },
  wizard: {
    navigationOpen: false,
    minContentWidth: 280,
    maxContentWidth: 1080,
  },
};

const defaultsRefresh: Record<AppLayoutProps.ContentType, AppLayoutState> = {
  default: {
    ...defaultsClassic.default,
  },
  cards: {
    ...defaultsClassic.cards,
  },
  form: {
    ...defaultsClassic.form,
    maxContentWidth: 976,
  },
  table: {
    ...defaultsClassic.table,
  },
  wizard: {
    ...defaultsClassic.wizard,
    maxContentWidth: 1280,
  },
};

interface AppLayoutState {
  navigationOpen?: boolean;
  toolsOpen?: boolean;
  minContentWidth?: number;
  maxContentWidth?: number | undefined;
}

export function applyDefaults(
  contentType: AppLayoutProps.ContentType,
  stateFromProps: AppLayoutState,
  isRefresh: boolean
): AppLayoutState {
  const contentTypeDefaults = isRefresh ? defaultsRefresh[contentType] : defaultsClassic[contentType];

  return {
    maxContentWidth: stateFromProps.maxContentWidth ?? contentTypeDefaults.maxContentWidth,
    minContentWidth: stateFromProps.minContentWidth ?? contentTypeDefaults.minContentWidth,
    navigationOpen: stateFromProps.navigationOpen ?? contentTypeDefaults.navigationOpen,
    toolsOpen: stateFromProps.toolsOpen ?? contentTypeDefaults.toolsOpen,
  };
}
