import { getMatchingBreakpoint } from '../../breakpoints';
import { createSingletonState } from '../use-singleton-handler';

function getIsMobile() {
  if (typeof window === 'undefined') {
    // assume desktop in server-rendering
    return false;
  }
  return getMatchingBreakpoint(window.innerWidth, ['xs']) !== 'xs';
}

export const useMobile = createSingletonState<boolean>({
  initialState: () => getIsMobile(),
  factory: handler => {
    const listener = () => handler(getIsMobile());
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  },
});
