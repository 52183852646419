import clsx from 'clsx';
import React from 'react';
import { Transition, TransitionStatus } from '../internal/components/transition';
import { DesktopDrawerProps, Drawer } from './drawer';
import { AppLayoutProps } from './interfaces';
import { SplitPanelWrapper } from './split-panel-wrapper';
import { ToggleButtons } from './toggle-buttons';
import { CircularButton } from './toggles/circular-button';
import useContentHeight from './utils/use-content-height';
import styles from './styles.css.js';
import { SplitPanelContextProps } from '../internal/context/split-panel-context';

interface ToolsAndSplitPanelProps {
  toolsHide: boolean;
  toolsOpen: boolean;
  toolsPanelTransitionState: TransitionStatus;
  isHidden: DesktopDrawerProps['isHidden'];

  splitPanelOpen: boolean;
  splitPanelTransitionState: TransitionStatus;

  drawerWidth: number;
  toolsWidth: number;
  splitPanelReportedSize: number;
  closedDrawerWidth: number;

  headerHeight: DesktopDrawerProps['topOffset'];
  footerHeight: DesktopDrawerProps['bottomOffset'];
  panelHeightStyle: ReturnType<typeof useContentHeight>['panelHeightStyle'];
  contentHeightStyle: ReturnType<typeof useContentHeight>['contentHeightStyle'];

  tools: React.ReactNode;
  splitPanel?: React.ReactNode;
  splitPanelContext: SplitPanelContextProps;

  ariaLabels: AppLayoutProps['ariaLabels'];

  disableContentPaddings: AppLayoutProps['disableContentPaddings'];
  isRefresh: boolean;
  isMobile: boolean;
  isMotionEnabled: boolean;

  setToolsPanelTransitionState: React.Dispatch<React.SetStateAction<TransitionStatus>>;
  setSplitPanelTransitionState: React.Dispatch<React.SetStateAction<TransitionStatus>>;
  onToolsToggle: DesktopDrawerProps['onToggle'];

  toggleRefs: DesktopDrawerProps['toggleRefs'];
}

export function ToolsAndSplitPanel({
  ariaLabels,
  closedDrawerWidth,
  contentHeightStyle,
  disableContentPaddings = false,
  drawerWidth,
  footerHeight,
  headerHeight,
  isHidden,
  isMobile,
  isMotionEnabled,
  isRefresh,
  onToolsToggle,
  panelHeightStyle,
  setSplitPanelTransitionState,
  setToolsPanelTransitionState,
  splitPanel,
  splitPanelContext,
  splitPanelReportedSize,
  splitPanelTransitionState,
  toggleRefs,
  tools,
  toolsHide,
  toolsOpen,
  toolsPanelTransitionState,
  toolsWidth,
  splitPanelOpen,
}: ToolsAndSplitPanelProps) {
  const splitPanelVisible =
    (splitPanelOpen || (splitPanelTransitionState === 'exiting' && isMotionEnabled)) && Boolean(splitPanel);
  const showSideButtonsBar = isRefresh && Boolean(splitPanel);

  const toolsVisible = !toolsHide && toolsOpen;

  const showToolsPanelExitState =
    isRefresh &&
    isMotionEnabled &&
    ((!toolsOpen && toolsPanelTransitionState === 'exiting') ||
      (toolsOpen && (toolsPanelTransitionState === 'enter' || toolsPanelTransitionState === 'exited')));

  const showSplitPanelExitState =
    isRefresh &&
    isMotionEnabled &&
    Boolean(splitPanel) &&
    ((!splitPanelOpen && splitPanelTransitionState === 'exiting') ||
      (splitPanelOpen && (splitPanelTransitionState === 'enter' || splitPanelTransitionState === 'exited')));

  const additionalSplitPanelMovement = toolsOpen ? 0 : closedDrawerWidth;
  const additionalToolsPanelMovement = splitPanelOpen ? 0 : closedDrawerWidth;

  return (
    <Transition in={toolsOpen} onStatusChange={setToolsPanelTransitionState} disabled={!isRefresh}>
      {(_, toolsPanelTransitionRef) => (
        <>
          <div
            style={{
              width: drawerWidth,
            }}
          >
            <div
              ref={toolsPanelTransitionRef}
              className={clsx(
                styles['panel-wrapper-outer'],
                {
                  [styles.mobile]: isMobile,
                  [styles.open]: toolsOpen,
                },
                isRefresh && {
                  [styles['enable-motion']]:
                    isMotionEnabled &&
                    toolsPanelTransitionState !== 'enter' &&
                    toolsPanelTransitionState !== 'entered' &&
                    toolsPanelTransitionState !== 'exit' &&
                    toolsPanelTransitionState !== 'exited',
                  [styles.refresh]: true,
                  [styles.hide]: showToolsPanelExitState && !splitPanelVisible && !disableContentPaddings,
                }
              )}
              style={{
                right:
                  showSideButtonsBar && (!toolsHide || !splitPanelVisible)
                    ? closedDrawerWidth
                    : isRefresh
                    ? 0
                    : undefined,

                ...(isMobile ? { top: headerHeight, bottom: footerHeight } : panelHeightStyle),
                ...(showToolsPanelExitState
                  ? {
                      transform: isMobile
                        ? `translateX(100%)`
                        : `translateX(${toolsWidth + additionalToolsPanelMovement}px)`,
                    }
                  : {}),
              }}
            >
              {splitPanel && (
                <>
                  {!isRefresh && <SplitPanelWrapper context={splitPanelContext}>{splitPanel}</SplitPanelWrapper>}

                  {isRefresh && (
                    <Transition in={splitPanelOpen} onStatusChange={setSplitPanelTransitionState}>
                      {(_, splitPanelTransitionRef) => {
                        return (
                          <div
                            ref={splitPanelTransitionRef}
                            className={clsx(
                              styles['panel-wrapper-inner'],
                              isRefresh && {
                                [styles['enable-motion']]:
                                  isMotionEnabled &&
                                  splitPanelTransitionState !== 'enter' &&
                                  splitPanelTransitionState !== 'entered' &&
                                  splitPanelTransitionState !== 'exit' &&
                                  splitPanelTransitionState !== 'exited',
                                [styles.refresh]: true,
                                [styles.hide]: showSplitPanelExitState && !disableContentPaddings,
                              }
                            )}
                            style={{
                              transform: showSplitPanelExitState
                                ? `translateX(${splitPanelReportedSize + additionalSplitPanelMovement}px)`
                                : undefined,
                              ...contentHeightStyle,
                              marginRight: splitPanelVisible ? -closedDrawerWidth : 0,
                              paddingRight: splitPanelVisible ? closedDrawerWidth : 0,
                            }}
                          >
                            {splitPanelVisible && (
                              <div
                                style={{
                                  display: !splitPanelVisible ? 'none' : undefined,
                                  height: '100%',
                                }}
                              >
                                <SplitPanelWrapper context={splitPanelContext}>{splitPanel}</SplitPanelWrapper>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Transition>
                  )}
                </>
              )}
              {!toolsHide && (
                <Drawer
                  visualRefresh={isRefresh}
                  type="tools"
                  isMobile={isMobile}
                  width={toolsWidth}
                  isOpen={toolsOpen || (toolsPanelTransitionState === 'exiting' && isMotionEnabled)}
                  onToggle={onToolsToggle}
                  toggleRefs={toggleRefs}
                  isHidden={isHidden}
                  externalizedToggle={Boolean(splitPanel)}
                  contentClassName={styles.tools}
                  closeClassName={styles['tools-close']}
                  toggleClassName={styles['tools-toggle']}
                  topOffset={isRefresh ? 0 : headerHeight}
                  bottomOffset={isRefresh ? 0 : footerHeight}
                  ariaLabels={ariaLabels}
                  extendRight={showSideButtonsBar && (!toolsHide || !splitPanelOpen) ? closedDrawerWidth : 0}
                  hasDividerWithSplitPanel={splitPanelVisible}
                >
                  {tools}
                </Drawer>
              )}
            </div>
          </div>

          {showSideButtonsBar && (!toolsHide || !splitPanelOpen) && (
            <ToggleButtons
              ariaLabels={ariaLabels}
              anyPanelOpen={!!splitPanelVisible || toolsVisible}
              opaqueBackground={
                splitPanelVisible &&
                isMotionEnabled &&
                (toolsPanelTransitionState === 'exiting' || toolsPanelTransitionState === 'entering')
              }
              isHidden={disableContentPaddings}
            >
              {!toolsHide && (
                <CircularButton
                  ref={toggleRefs.toggle}
                  className={styles['tools-toggle']}
                  selected={toolsOpen}
                  iconName="status-info"
                  ariaLabel={ariaLabels?.toolsToggle}
                  onClick={() => onToolsToggle(!toolsOpen)}
                  ariaExpanded={toolsOpen}
                />
              )}
              <SplitPanelWrapper context={splitPanelContext} isCopy={true}>
                {splitPanel}
              </SplitPanelWrapper>
            </ToggleButtons>
          )}
        </>
      )}
    </Transition>
  );
}
